




















































































































































import { Component, Vue } from "vue-property-decorator";
import {
  ApiDoctorName,
  ApiDoctorArticleUpdate,
  ApiObjectRecordsDelete,
} from "@/apis";
import { breadcrumb } from "@/utils/breadcrumb";
import * as utils from "@/utils";
import { DirectoryEnum } from "@/constants";
import { uploadFile } from "@/utils/alioss";
@Component
export default class MessageCenterAdd extends Vue {
  //新增表单字段
  data: any = {
    module: "101",
    device: null,
    title: null,
    url: null,
    thumb_url: null,
    state: null,
    qignzhou_type: null,
  };
  loading = false;
  uploadDisabled = false;
  query = {
    dialogVisible: false,
  };
  imgArr: any = []; // 上传的图片地址;
  urls: any = [];

  rules = {
    // doctor_id: [{ required: true, message: "请输入医生id", trigger: "blur" }],
    // doctor_name: [
    //   { required: true, message: "请输入医生姓名", trigger: "blur" },
    // ],
    device: [{ required: true, message: "请选择推送应用", trigger: "change" }],
    module: [{ required: true, message: "请选择分类", trigger: "change" }],
    title: [{ required: true, message: "请输入标题", trigger: "blur" }],
    url: [{ required: true, message: "请输入链接", trigger: "blur" }],
    // time: [{ required: true, message: "请选择起止时间", trigger: "change" }],
    code_list_detail: [
      { required: true, message: "请选择推送用户", trigger: "change" },
    ],
    push: [{ required: true, message: "请选择推送方式", trigger: "change" }],
  };
  back() {
    this.$router.go(-1);
  }
  mounted() {
    this.data = JSON.parse(this.$route.query.row as any);
    this.data.module = this.data.module.toString();
    if (this.data.thumb_url) {
      this.imgArr = [this.data.thumb_url];
      const tempArr: any = [];
      tempArr.push({
        name: new Date(),
        url: this.data.thumb_url,
      });
      this.urls = tempArr;
      if (this.imgArr.length >= 1) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
    }
    this.data.order_weight = this.data.order_weight ? true : false;
  }
  doctorIdBlurChange() {
    ApiDoctorName({ doctor_id: this.data.doctor_id }).then((res) => {
      if (res.success) {
        if (!res.data) {
          this.data.doctor_name = "";
          this.data.doctor_id = "";
          this.$message.error("请输入正确的医生id。");
        } else {
          this.data.doctor_name = res.data.name;
        }
      } else {
        this.data.doctor_name = "";
        this.$message.error("请输入正确的医生id。");
      }
    });
  }
  async requestContentUpdate() {
    console.log(this.data, "=====this.data");
    delete this.data.createdate;
    delete this.data.updatedate;
    delete this.data.updateuserid;
    delete this.data.createuserid;
    this.data.order_weight = this.data.order_weight ? 1 : 0;
    const result = await ApiDoctorArticleUpdate(this.data);
    if (result.success) {
      this.$message({
        message: "编辑成功",
        type: "success",
      });
      this.back();
    }
  }
  beforeAvatarUpload(file: any) {
    const isLt = file.size / 1024 < 200;
    const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
    const extension =
      testmsg === "jpg" ||
      testmsg === "jpeg" ||
      testmsg === "png" ||
      testmsg === "bmp";

    if (!extension) {
      this.$message({
        message: "格式不支持!",
        type: "error",
      });
      Promise.reject("");
    }
    if (!extension) {
      setTimeout(() => {
        if (!isLt) {
          this.$message.error("超出文件限制大小");
          Promise.reject("");
        }
      }, 0);
    } else {
      if (!isLt) {
        this.$message.error("超出文件限制大小");
        Promise.reject("");
      }
    }

    const isSize = new Promise(function (resolve, reject) {
      const _URL = window.URL || (window as any).webkitURL;
      const image = new Image();
      image.onload = function () {
        const valid = image.width / image.height == 1;
        valid ? resolve() : reject();
      };
      image.src = _URL.createObjectURL(file);
    }).then(
      () => {
        return file;
      },
      () => {
        this.$message.error("尺寸不符合要求");
        return Promise.reject("");
      }
    );
    return extension && isSize;
  }
  handleExceed() {
    this.$message.warning(`仅可上传1张图片`);
  }
  //新增-移除事件
  async handleRemove(file: any, fileList: any) {
    console.log(file, fileList);
    this.uploadDisabled = false;
    if (!this.data.thumb_url) return;
    const result = await ApiObjectRecordsDelete({
      object_key_list: [this.data.thumb_url],
      userid: utils.get("userId"),
    });
    // if (result.success && fileList.length == 0) {
    this.data.thumb_url = "";
    // }
  }
  //上传图片change事件
  handleLimit(file: any, fileList: any) {
    if (fileList.length >= 1) {
      this.uploadDisabled = true;
      // this.$set(this.eqObj, "uploadDisabled", true);
      console.log(this.uploadDisabled);
    } else {
      this.uploadDisabled = false;
      // this.$set(this.eqObj, "uploadDisabled", false);
    }
  }
  // 新增-上传附件手动
  async uploadHttpRequestPicture(params: any) {
    console.log("新增-上传附件手动", params);
    const file = params.file;
    const fileName = file.name;
    const path: any = await this.uploadAttachment(file);
    // 原本上传照片的逻辑
    this.data.thumb_url = path;
    console.log(this.data.thumb_url, " this.data.thumb_url");
  }
  // 上传
  async uploadAttachment(file: File) {
    //doctorContent/img
    const fileName = new Date().getTime().toString();
    const fileExt = file.name.split(".").pop();

    const res = await uploadFile(
      DirectoryEnum.DOCTOR_CONTENT,
      file,
      fileName + "." + fileExt,
      "/img"
    );
    return res;
  }
  //doctorContent/img

  submitForm() {
    const formRef = this.$refs["ruleForm"] as any;
    formRef.validate((valid: boolean) => {
      if (valid) {
        this.requestContentUpdate();
      }
    });
  }
}
